import React from 'react';
import styled, {CSSObject} from '@emotion/styled';
import {navigate, withPrefix} from 'gatsby';

import {StyledType, SystemIcon} from '@workday/canvas-kit-react';
import {BaseButton} from '@workday/canvas-kit-react/button';
import {Flex} from '@workday/canvas-kit-react/layout';
import {SidePanel, useSidePanel} from '@workday/canvas-kit-preview-react/side-panel';
import {BodyText, Subtext} from '@workday/canvas-kit-react/text';
import {colors, space, type} from '@workday/canvas-kit-react/tokens';

import {
  chevronDownSmallIcon,
  componentIcon,
  patternIcon,
  playbookIcon,
  rocketIcon,
  shapesIcon,
  bookOpenIcon,
  infoIcon,
} from '@workday/canvas-system-icons-web';

import {CanvasSystemIcon} from '@workday/design-assets-types';

import {useNavigation} from './Navigation';
import {usePlatformSwitcher} from '../PlatformSwitcher';

import {voiceOverFocusDelay} from '../../utils/a11y';
import {L1NavItemWidth, sidebarWidthCollapsed, sidebarWidthExpanded} from '../../utils/breakpoints';
import {Hierarchy} from '../../utils/useHierarchy';
import {lastPatternTypeKey, restoreFromSessionStorage} from '../../utils/sessionStorage';
import {endsWith} from '../../utils/strings';

/*
High-level Notes
-------------------------------------------------------------------------------
Every item in the navigation hierarchy is represented by a Hierarchy item.

Most items which have children do NOT correspond to an actual page. For
example, `/components/buttons` has children (e.g., `/components/buttons/button`),
but `/components/buttons` itself doesn't correspond to an actual page (i.e.,
interacting with it in the nav shows/hides its children but doesn't take you to
an actual page). These items will have their isPage property set to false.

Some items which have children DO correspond to an actual page. For example,
`/whats-new/announcements` has children (e.g., `/whats-new/announcements/canvas-v9-announcements`),
AND `/whats-new/announcements` itself corresponds to an actual page (i.e.,
interacting with it in the nav takes you to an actual page INSTEAD of
showing/hiding its children). These items will have their isPage property set
to true. These pages will include links to their children in their content
(e.g., the `/whats-new/announcements` page will contain a link to
`/whats-new/announcements/canvas-v9-announcements`).
*/

/**
 * Converts a path a string suitable for an HTML id (used to assign ids in navigation)
 */
export const pathToNavId = (path: string) => 'nav-' + path.substring(1).split('/').join('-');

const isActive = (item: Hierarchy, activePath: string) => {
  if (!item.isPage) return false;

  // First, check if we're on a Pattern Example page. We have special logic
  // here to determine if we arrived via a (Documented) Pattern or a Proto
  // Pattern.
  if (activePath.match(/\/patterns\/examples\/\d+/g)) {
    const lastPatternType = restoreFromSessionStorage(lastPatternTypeKey);

    if (lastPatternType === 'documented') {
      return item.title === 'Patterns';
    } else if (lastPatternType === 'proto') {
      return item.title === 'Proto Patterns';
    } else {
      return false;
    }
  }

  // At the moment, all patterns dynamically retrieved from the WPL are proto
  // patterns -- ensure we highlight the Proto Patterns item in the Sidebar
  if (activePath.match(/\/patterns\/\d+/g)) {
    return item.title === 'Proto Patterns';
  } else if (
    activePath.match(/\/patterns\/.+/g) &&
    !endsWith(activePath, 'overview') &&
    !endsWith(activePath, 'proto')
  ) {
    // Or for documented patterns (whidh don't have a numerical id in the path),
    // highlight the Patterns item in the Sidebar
    return item.title === 'Patterns';
  }

  return activePath.startsWith(item.path);
};

const isExpanded = (item: Hierarchy, activePath: string) => {
  if (!item.children) return false;
  return activePath.startsWith(item.path);
};

const getL1NavItemButtonColors = (active: boolean) => {
  // The 99 at the end is adjusting the alpha of the color token to 60%.
  // Source: https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
  const backgroundColor = colors.blueberry200 + '99';
  return {
    default: {
      background: active ? backgroundColor : 'transparent',
      label: active ? colors.blackPepper300 : colors.licorice300,
      icon: active ? colors.blackPepper300 : colors.licorice100,
    },
    hover: {
      background: active ? backgroundColor : colors.soap200,
      label: active ? colors.blackPepper300 : colors.blackPepper300,
      icon: active ? colors.blackPepper300 : colors.licorice200,
    },
    active: {
      background: backgroundColor,
      label: colors.blackPepper300,
      icon: colors.blackPepper300,
    },
    focus: {
      background: backgroundColor,
      label: colors.blackPepper300,
      icon: colors.blackPepper300,
    },
    // disabled: {
    //   background: colors.blueberry400,
    // },
  };
};

type L1NavItemProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  item: Hierarchy;
  icon: CanvasSystemIcon;
};

const L1NavItem = ({item, icon, ...elemProps}: L1NavItemProps) => {
  const nav = useNavigation();

  const active = nav.activePath().startsWith(item.path);

  return (
    <BaseButton
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: space.xxxs,
      }}
      id={pathToNavId(item.path)}
      aria-current={active ? 'true' : undefined}
      border={0}
      borderRadius="l"
      flexShrink={0}
      width={L1NavItemWidth}
      colors={getL1NavItemButtonColors(active)}
      paddingY="s"
      onClick={() => {
        nav.setIsL1Activated(true);
        navigate(item.path);

        // Reset isL1Activated after a short delay so auto-focusing the h1
        // works from this point forward.
        setTimeout(() => {
          nav.setIsL1Activated(false);
        }, voiceOverFocusDelay * 2);
      }}
      onMouseEnter={() => {
        nav.setIsMenuCollapsed(false);
        nav.setHoveredL1Path(item.path);
        nav.setIsHoveredMenuHeldOpen(true);
      }}
      onMouseLeave={() => {
        nav.setIsHoveredMenuHeldOpen(false);
        if (nav.isHomePage()) {
          nav.setIsMenuCollapsed(true);
        }
        // Mousing over an L1 triggers its corresponding L2 menu (aka the "hovered
        // menu"). If we mouse out of the L1, we want to reset the hovered menu
        // back to the active L1 menu (or to no menu at all if we're on the home
        // page). However, we only want to do this if we're NOT holding the hovered
        // menu open (e.g., if we moused out of an L1 and into its corresponding L2
        // menu). Therefore, we reset the hovered menu on a short delay using
        // setTimeout.
        setTimeout(() => {
          if (!nav.isHoveredMenuHeldOpen()) {
            nav.setHoveredL1Path('');
          }
        }, 200);
      }}
      {...elemProps}
    >
      <BaseButton.Icon icon={icon} size="large" />
      <BaseButton.Label
        css={{
          ...type.levels.subtext.medium,
          color: active ? colors.blackPepper300 : colors.licorice300,
          fontWeight: type.properties.fontWeights.bold,
          letterSpacing: '-0.08px',
          lineHeight: 'normal',
        }}
      >
        {item.title}
      </BaseButton.Label>
    </BaseButton>
  );
};

const renderNavItemChildren = (item: Hierarchy, level: number) =>
  item.children ? (
    <Flex
      as="ul"
      aria-labelledby={pathToNavId(item.path)}
      flexDirection="column"
      paddingInlineStart="s"
    >
      {item.children.map(child => (
        <NavItem key={child.path} item={child} level={level + 1} />
      ))}
    </Flex>
  ) : null;

const L2NavLabelButton = ({...elemProps}) => <Flex as="button" {...elemProps} />;
const L2NavLabelLink = ({...elemProps}) => <Flex as="a" {...elemProps} />;

const NavLabelButton = ({...elemProps}) => <Subtext size="large" as="button" {...elemProps} />;
const NavLabelLink = ({...elemProps}) => <Subtext size="large" as="a" {...elemProps} />;

const navLabelStyles = (active: boolean, isExpandable: boolean): CSSObject => {
  // The B3 at the end is adjusting the alpha of the color token to 70%.
  // Source: https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
  const backgroundColor = colors.blueberry200 + 'B3';
  return {
    backgroundColor: active ? backgroundColor : 'transparent',
    border: 0,
    cursor: 'pointer',
    textAlign: isExpandable ? 'left' : undefined,
    textDecoration: isExpandable ? undefined : 'none',
    transition:
      'box-shadow 120ms linear, border 120ms linear, background-color 120ms linear, color 120ms linear',
    '&:hover': {
      backgroundColor: active ? backgroundColor : colors.soap300,
    },
  };
};

const renderNavItem = (
  item: Hierarchy,
  level: number,
  active: boolean,
  expanded: boolean,
  onClick: React.MouseEventHandler
) => {
  const isExpandable = !!(!item.isPage && item.children);

  const labelProps = {
    'aria-current': active ? 'true' : undefined,
    'aria-expanded': isExpandable ? (expanded ? 'true' : 'false') : undefined,
    href: isExpandable ? undefined : item.path ? withPrefix(item.path) : undefined,
    id: pathToNavId(item.path),
    onClick,
  };

  // L2 nav items render differently than other levels...
  if (level === 2) {
    const L2Label = isExpandable ? L2NavLabelButton : L2NavLabelLink;

    return (
      <Flex as="li" flexDirection="column">
        <L2Label
          {...labelProps}
          css={{
            ...navLabelStyles(active, isExpandable),
            borderRadius: '24px',
            justifyContent: 'space-between',
            padding: `${space.xs} ${space.s} ${space.xs} ${space.s}`,
          }}
        >
          <BodyText size="small" fontWeight="bold" color="blackPepper300">
            {item.title}
          </BodyText>
          {isExpandable && (
            <SystemIcon
              icon={chevronDownSmallIcon}
              css={{
                transition: '150ms ease-out',
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          )}
        </L2Label>
        {isExpandable && expanded && renderNavItemChildren(item, level)}
      </Flex>
    );
  } else {
    // ... L3 nav items and deeper render identically
    const NavLabel = isExpandable ? NavLabelButton : NavLabelLink;

    return (
      <Flex as="li" flexDirection="column">
        <NavLabel
          {...labelProps}
          css={{
            ...navLabelStyles(active, isExpandable),
            color: colors.blackPepper300,
            borderRadius: '18px',
            fontWeight:
              active || (isExpandable && expanded) ? type.properties.fontWeights.bold : undefined,
            padding: `${space.xxs} ${space.s} ${space.xxs} ${space.s}`,
          }}
        >
          {item.title}
        </NavLabel>
        {isExpandable && expanded && renderNavItemChildren(item, level)}
      </Flex>
    );
  }
};

type NavItemProps = {
  item: Hierarchy;
  level: number;
};

// NavItem level should be no less than 2 (level 1 is renders L1NavItem)
const NavItem = ({item, level}: NavItemProps) => {
  const nav = useNavigation();

  const [expanded, setExpanded] = React.useState(isExpanded(item, nav.activePath()));

  const active = isActive(item, nav.activePath());

  // If the active path has changed, re-evaluate expanded value to see if we
  // need to expand the item (for cases when you click on an L1 which takes you
  // to L3 or deeper). If the item is already expanded, however, don't touch it
  // (leave it expanded).
  React.useEffect(() => {
    if (!expanded) {
      setExpanded(isExpanded(item, nav.activePath()));
    }
  }, [nav.activePath()]);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (item.isPage) {
      navigate(item.path);
    } else if (item.children) {
      setExpanded(!expanded);
    }
  };

  return renderNavItem(item, level, active, expanded, handleClick);
};

type L1MetadataItem = {
  category: string;
  icon: CanvasSystemIcon;
};

const L1Metadata: L1MetadataItem[] = [
  {category: 'get-started', icon: rocketIcon},
  {category: 'styles', icon: shapesIcon},
  {category: 'components', icon: componentIcon},
  {category: 'patterns', icon: patternIcon},
  {category: 'frameworks', icon: playbookIcon},
  {category: 'guidelines', icon: bookOpenIcon},
  {category: 'help', icon: infoIcon},
];

const StyledSidePanel = styled(SidePanel)<StyledType>({
  backgroundColor: colors.frenchVanilla100,
  // TODO: Figure out why fix for clipped L1 focus rings (see below) broke
  // the sidenav hover behavior on the Home page (i.e., user needs to
  // mouse from L1 to L2 menu quickly in order to keep the L2 menu
  // visible -- otherwise, if they mouse too slowly, the L2 menu
  // disappears before they've reached it which is extremely annoying)
  // See commit: https://ghe.megaleo.com/design/canvas-site/pull/1064/commits/9007373be8d96a09e3968001f4247c4bfe2c7716
  // Also see commit: https://ghe.megaleo.com/design/canvas-site/pull/1064/commits/7ed8f33139f56cb4284efcc6720bfd31e3ce6468
  // Both commits are from this PR: https://ghe.megaleo.com/design/canvas-site/pull/1064
  padding: `0 ${space.xxs}`,
  position: 'fixed',
  top: space.xxl,
  left: 0,
  // zIndex necessary to get SidePanel to display on top of hero SVGs
  zIndex: 2,
});

export const SidebarNew = ({...elemProps}) => {
  const {panelProps} = useSidePanel();

  const nav = useNavigation();
  const platformSwitcher = usePlatformSwitcher();
  const hierarchy = platformSwitcher.hierarchy;

  // To determine which L1's menu is displayed: choose the hovered L1 first,
  // otherwise the active (i.e., clicked) L1.
  const displayedL1Path = nav.hoveredL1Path() || nav.activeL1Path();
  const displayedL1Children = hierarchy.children?.find(
    ({path}) => path === displayedL1Path
  )?.children;

  return (
    <nav role="navigation" aria-label="Site">
      <StyledSidePanel
        as="aside"
        role="region"
        {...panelProps}
        expanded
        css={{
          maxWidth: 'none',
          // Reverted focus clipping fix here, see above TODO
          width: nav.isMenuCollapsed() ? sidebarWidthCollapsed : sidebarWidthExpanded,
        }}
        {...elemProps}
      >
        <Flex height="calc(100% - 64px)">
          {/* TODO: Reverted focus clipping fix here, see above TODO */}
          <Flex flexDirection="column" overflow="auto" paddingY="xxs" gap="xxxs">
            {L1Metadata.map(metadataItem => {
              const item = hierarchy.children?.find(
                child => child.category === metadataItem.category
              );
              return item ? (
                <L1NavItem key={item.path} item={item} icon={metadataItem.icon} />
              ) : null;
            })}
          </Flex>
          {!nav.isMenuCollapsed() && (
            // This outer Flex is necessary because we need its paddingLeft
            // space to capture the mouseEnter event in order to hold the
            // hovered menu open
            <Flex
              // TODO: Reverted focus clipping fix here, see above TODO
              paddingLeft="xxs"
              onMouseEnter={() => {
                nav.setIsMenuCollapsed(false);
                // Normally, mousing out of an L1 item resets hoveredL1. We
                // don't want to do this if we moused out of an L1 item and
                // into it's corresponding L2 menu (aka the "hovered menu");
                // instead, we want the hovered menu to persist.
                nav.setIsHoveredMenuHeldOpen(true);
              }}
              onMouseLeave={() => {
                // We no longer need to hold the hovered menu open
                nav.setIsHoveredMenuHeldOpen(false);
                if (nav.isHomePage()) {
                  nav.setIsMenuCollapsed(true);
                } else {
                  // Reset hoveredL1 if we mouse out of the L2 menu
                  nav.setHoveredL1Path('');
                }
              }}
            >
              <Flex
                as="ul"
                aria-labelledby={pathToNavId(displayedL1Path)}
                backgroundColor="soap100"
                borderRadius={16}
                flexDirection="column"
                marginY="xxs"
                paddingY="s"
                paddingX="xxs"
                width={250}
                overflow="auto"
              >
                {displayedL1Children &&
                  displayedL1Children.map((item: Hierarchy) => (
                    <NavItem key={item.path} item={item} level={2} />
                  ))}
              </Flex>
            </Flex>
          )}
        </Flex>
      </StyledSidePanel>
    </nav>
  );
};
